/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";


@media(max-height: 768px){
	.banner-thankyou{min-height: 87vh;}
}
@media(max-height: 720px){
	.banner-thankyou{
		min-height: 85vh;
		.desc{top: 70%;}
	}
}
@media(min-width: 1px) and (max-width: 1700px){
	.sec-aboutus{
		.flwdev{
			&.flw-01{
				img{width: 350px;}
			}
		}
		.rings{
			margin-top: 38%;
			img{width: 350px;}
		}
	}
	.sec-wedding-service{
		.flwdev{
			&.flw-04{left: -80px;}
		}
	}
	.coming-soon{
		.left{padding-left: 40px; padding-right: 40px;}
	}
	.detailsection{
		.flw-love-detail{
			img{width: 350px;}
		}
	}
	.aboutbox{
		.flw-right-gallery{
			margin-top: 320px;
			img{width: 240px;}
		}
		.flw-left-gallery{
			img{width: 230px;}
		}
	}
}

@media(min-width: 1px) and (max-width: 1610px){
	.sec-ourvenue .wrapper, .our-address .box-ouraddress{width: 1300px;}
}
@media(min-width: 1px) and (max-width: 1550px){
	.sec-aboutus{
		> figure{
			img{height: 800px;}
		}
		.flwdev{
			&.flw-01{
				img{width: 280px;}
			}
		}
		.rings{
			margin-top: 34%;
			img{width: 280px;}
		}
	}
	.sec-contact{
		> figure{
			img{height: 700px;}
		}
		.flwdev{
			&.flw-02{
				img{width: 300px;}
			}
		}
	}
	.sec-wedding-service{
		padding-bottom: 200px;
		.flwdev{
			&.flw-03{
				margin-top: 60px;
				img{width: 500px;}
			}
			&.flw-04{
				left: -15px; margin-top: 62%;
				img{width: 250px;}
			}
		}
		.border-white{bottom: 150px;}
	}
	.aboutbox{
		.flw-left-service{
			img{width: 250px;}
		}
		.flw-right-service{
			img{width: 350px;}
		}
		.flw-right-gallery{
			img{width: 200px;}
		}
		.flw-left-gallery{
			img{width: 180px;}
		}
	}
	.explore-venue{
		.flw-left-venue{
			img{width: 300px;}
		}
	}
	.coming-soon{
		.left{
			.content-cs{
				h1{margin-bottom: 35px;}
				h5{line-height: 30px; font-size: 18px; margin-bottom: 30px;}
				.flw-right-cs{
					left: -100px; top: 90%;
					img{width: 200px;}
				}
			}
		}
		.right{
			.slidercs{
				.list-slide{
					.desc{left: 30px; right: 30px;}
				}
			}
		}
	}
	.other-venue-section{
		.flw-left-other{
			img{width: 220px;}
		}
	}
	.contact-content{
		.flw-contact-right{
			img{width: 350px;}
		}
		.flw-contact-left{
			img{width: 250px;}
		}
	}
	.popup-gallery {
		.img-large {
			.list-slide {
				.caps-large {
					width: 860px; padding-bottom: 110px; font-size: 17px; padding-top: 12px;
					&:after {
						bottom: 95px;
					}
				}
			}
		}
		.box-thumb-slider {
			padding: 12px 0; width: 860px;
			.wrapper {width: 760px;}
			.list-slide {margin: 0 6px;}
			.slick-prev, .slick-next {width: 13px; background-size: 26px; height: 25px;}
			.slick-prev {
				left: -30px;
				&:hover {left: -34px;}
			}
			.slick-next {
				right: -30px;
				&:hover {right: -34px;}
			}
		}
	}
}

@media(min-width: 1px) and (max-width: 1440px){
	.sec-aboutus{
		.rings{margin-top: 38%;}
	}
	.sec-wedding-service{
		padding-top: 120px;
		.flwdev{
			&.flw-03{margin-top: -40px;}
		}
	}
	.aboutbox{
		.rings{left: -35px;}
		.flw-right{
			img{width: 350px;}
		}
		.flw-right-gallery{display: none;}
		.flw-left-gallery{margin-top: 100%;}
	}
	.our-team{
		.flw-left{
			img{width: 300px;}
		}
	}
	.coming-soon{
		min-height: 650px;
		.left{padding-top: 20px; padding-bottom: 20px;}
		.right{
	        .slidercs{
	            .list-slide{min-height: 650px;}
	        }
	    }
		.box-partner-cs{width: 34.5%;}
	}
	.detailsection{
		.flw-love-detail{left: -50px}
		.flw-right-gallery{
			img{width: 250px;}
		}
	}
	.faq-content{
		.flw-left-faq{
			img{width: 180px;}
		}
	}
	.contact-content{
		.flw-contact-right{
			img{width: 290px;}
		}
		.flw-contact-left{
			img{width: 200px;}
		}
	}
	.box-contact-right{
		.inner{
			padding-top: 40px; width: 640px; padding-left: 50px; padding-right: 50px;
			h3{margin-bottom: 20px;}
			p{font-size: 14px; line-height: 20px; margin-bottom: 20px;}
			.line-v{height: 75px;}
			.box-form{
				input[type="text"], input[type="email"], textarea{font-size: 16px;}
				textarea{height: 70px;}
				.row{margin-bottom: 10px;}
				.lfaq{font-size: 13px;}
				.tdate{
					.ic-cus{top: 5px;}
				}
			}
		}
	}
}
@media(min-width: 1px) and (max-width: 1440px) and (max-height: 750px){
	.coming-soon{
		.left{
			.content-cs{
				.flw-right-cs{top: 80%;}
			}
		}
	}
}
@media(min-width: 1px) and (max-width: 1350px){
	.detailsection{
		.flw-love-detail{display: none;}
	}
	.popup-gallery{
		.img-large{
			.list-slide{
				.caps-large{width: 1170px; padding-bottom: 180px;}
			}
		}
		.box-thumb-slider{
			width: 1170px; padding-left: 40px; padding-right: 40px; @include boxSizing(border-box);
			.wrapper{width: 100%;}
			.slick-prev{
				left: -25px;
				&:hover{left: -28px;}
			}
			.slick-next{
				right: -25px;
				&:hover{right: -28px;}
			}
		}
	}
}
@media(min-width: 1px) and (max-width: 1310px){
	.sec-aboutus{
		.flwdev{
			&.flw-01{margin-top: 130px;}
		}
	}
	.sec-ourvenue .wrapper, .our-address .box-ouraddress{width: 1170px;}
	.sec-contact{
		.flwdev{
			&.flw-02{
				margin-top: 25%;
				img{width: 250px;}
			}
		}
	}
	.sec-wedding-service{
		padding-top: 80px; padding-bottom: 100px;
	 	.flwdev{
	 		&.flw-03{
	 			img{width: 400px;}
	 		}
	 	}
	 	.border-white{bottom: 70px; top: 185px;}
	}
	.sec-ourvenue{
	 	.inner-ourvenue{
	 	 	.slide-venue{width: 990px;}
	 	}
		
	}
	.our-address{
		.box-ouraddress{
			.inner-ouraddress{
				.left{padding-left: 50px;}
				.right{padding-right: 20px;}
			}
		}
	}
	.sec-maps{
		.mapshome{height: 415px;}
	}
	.aboutbox{
		.flw-right-service{display: none;}
		.flw-left-gallery{
			img{width: 140px;}
		}
	}
	.explore-venue{
	 	.flw-left-venue{left: -60px;}
	}
}
@media(min-width: 1px) and (max-width: 1200px){
	.wrapper{width: 905px;}
	header{
		.nav_right{
			.nav_header{
				li{padding: 0 15px;}
			}
			.nav_lang{
				a{font-size: 10px;}
			}
			.nav_sosmed, .nav_sosmed_float{
				padding-top: 28px;
				.icwp.ic-fb, .icwp.ic-ig, .icwp.ic-tw, .icwp.ic-yt, .icwp.ic-gplus{width: 19px;height: 15px; background-size: auto 200%;}
				.icwp.ic-fb-float, .icwp.ic-ig-float, .icwp.ic-tw-float, .icwp.ic-gplus-float{width: 19px;height: 15px; background-size: auto 200%;}
				a{
					padding-left: 5px; width: 19px; height: 15px;
					img{height: 30px;}
					&:hover{}
				}
			}
		}
	}
	.slidehome{
		.list-slide{
			.desc{
				font-size: 28px; line-height: 35px;
				.logo-lg{
					img{width: 350px;}
				}
			}
		}
		.sliderhome{
			.slick-prev, .slick-next{display: none !important;}
		}
	}
	.sec-aboutus{
		 > figure{
		 	position: relative;left: -80px; top: -80px;
			img{height: 600px;}
		 }
		.flwdev{
			&.flw-01{
				img{width: 250px;}
			}
		}
		.rings{
			margin-top: 44%;
			img{width: 330px;}
		}
		.trigger-about, .trigger-about2{top: 20%;}
	}
	.sec-content{
		padding-top: 117px;
		.desc-sechome{
			position: relative; top: 0; @include transform(translate(0)); left: 208px; margin-bottom: 35px;
			.quotes{margin-bottom: 50px;}
		}
	}
	.sec-contact{
		padding-top: 0;
		> figure{
			position: relative; top: -150px;
			img{height: 600px;}
		}
		.flwdev{
			&.flw-02{margin-top: 41%;}
		}
		.desc-sechome{margin-bottom: 0;}
	}
	.sec-wedding-service{
		padding-top: 0; padding-bottom: 80px;
		.border-white{bottom: 50px;}
		.flwdev{
			&.flw-03{margin-top: -90px;}
			&.flw-04{display: none;}
		}
		.content-wedding{
			.box-weddingservice{
				.list-wedding{
					padding-left: 20px; padding-right: 20px; margin: 0 2%; width: 48%;
					&:nth-child(2n){float: right;}
				}
			}
			.box-weddingserviceone{
				padding: 20px 40px 60px;
				.right{padding-top: 45px;}
			}
		}
	}
	.sec-ourvenue .wrapper, .our-address .box-ouraddress{width: 905px;}
	.sec-ourvenue{
		padding-top: 60px;padding-bottom: 50px;
		.inner-ourvenue{
			.slide-venue{
				width: 827px;
				.slidervenue{
					margin-bottom: 0;
					.list-slide{
						height: 647px;
						figure{
							img{min-height: 100%; min-width: 100%; max-width: none;}
						}
					}
					.slick-dots{display: none !important;}
				}
			}
			.desc-venue{width: 530px;}
		}
	}
	.our-address{
		.box-ouraddress{
			.inner-ouraddress{
				@include fullfloat;padding-bottom: 200px;
				*{@include boxSizing(border-box);}
				.left{width: 49%;}
				.right{
					width: 50%;
					.career-icon{margin-bottom: 30px;}
					.content-careerhome{
						.text-careerhome{position: relative;}
						h3{position: absolute; left: 150px; top: -115px;}
						.box-sosmed-lg{
							position: absolute; left: 0; right: 0; text-align: center; bottom: 30px;
							a{width: 14%; float: none; display: inline-block; vertical-align: top;}
						}
					}
				}
			}
		}
	}
	.aboutbox{
		.rings{
			img{width: 220px;}
		}
		.flw-left-service{left: -60px; margin-top: 60%;}
		&.vendorgallery{
			margin-bottom: 0;
			.btn-inqury{display: none;}
		}
	}
	.about-story{
		*{@include boxSizing(border-box);}
		.box-slide-story{
			width: 232px;
			.line-v{left: 173px;}
			.navsliderstory{
				.slick-prev, .slick-next{left: 167px;}
				.list-story{
					padding-right: 0;
					.a-list{
						width: 173px; padding-right: 48px;
						.a-active{
							h1{font-size: 50px;}
							h3{font-size: 24px;}
						}
					}
				}
				.slick-list{max-height: 590px !important;}
			}
		}
		.box-slider-story-desc{width: 644px;}
	}
	.philosophy{
		padding-bottom: 150px;
		.box-two{
			&.visi{
				padding: 0;
				.list{
					width: 50%; padding: 0 15px;
					&:nth-child(2n){padding-right: 0;}
					&:nth-child(2n+1){padding-left: 0;}
				}
			}
		}
	}
	.our-team{
		p{
			&.filsof{margin-bottom: 90px;}
		}
		.title-content{margin-bottom: 30px;}
		.flw-left{
			margin-top: 50%; left: -80px;
			img{width: 230px;}
		}
	}
	.explore-venue{
		.flw-left-venue{display: none;}
	}
	.coming-soon{
		.left{
			width: 50%;
			.content-cs{
				h1{font-size: 45px;}
			}
		}
		.right{
			width: 50%;
			.slidercs{
				.list-slide{
					.desc{
						padding-left: 55px; padding-right: 55px;
						h1{font-size: 30px; margin-bottom: 20px; line-height: 38px;}
						p{font-size: 14px; line-height: 20px;}
					}
				}
			}
		}
		.box-partner-cs{width: 42.5%; bottom: 20px;}
	}
	.detailsection{
	 	.flw-right-gallery{
	 		margin-top: 48%;
	 		img{width: 200px;}
	 	}
	 	.btn-inqury{display: none;}
	}
	.other-venue-section{
		.flw-left-other{display: none;}
	}
	.popup-gallery{
		.img-large{
			.list-slide{
				.caps-large{
					width: 905px; padding-bottom: 130px; font-size: 18px;
					&:after {bottom: 110px;}
				}
			}
		}
		.box-thumb-slider{
			width: 905px;
			.list-slide{
				figure{
					img{height: 70px;}
				}
			}
		}
	}
	.box-filter{
		margin-bottom: 65px;
		select{padding-left: 20px; font-size: 16px;}
		.list-filter-gallery{
			*{@include boxSizing(border-box);}
			&.svpartner{width: 207px;}
			&.svenue{width: 140px;}
	        &.swtheme{width: 205px;}
	        &.sphoto{width: 185px;}
			&.search-filter{
				width: 160px;
				.btn-oval{padding-left: 20px; padding-right: 20px;}
			}
		}
	}
	.contact-content{
		.flw-contact-right{
			margin-top: 20%;
			img{width: 200px;}
		}
		.flw-contact-left{
			margin-top: 105%;
			img{width: 170px;}
		}
	}
	.venue-location{
		.ven-left{width: 695px;}
		.ven-right{width: 400px;}
	}
}
@media(min-width: 1px) and (max-width: 1024px){
	h1{font-size: 45px;}

	header{
	 	.nav_right{
	 		a{font-size: 14px;}
	 	}
	}
	.subtitle{font-size: 25px; line-height: 40px;}
	.sec-aboutus{
	 	.flwdev{
	 		&.flw-01{
	 			img{width: 220px;}
	 		}
	 	}
	 	.rings{margin-top: 50%; right: 13.19%;}
	 	.desc-sechome{margin-bottom: 0;}
	}
	.sec-contact{
	 	> figure{right: -120px;}
	}
	.banner{
		height: 600px; position: relative;
		figure{
			position: absolute; top: 0; bottom: 0; left: 0; right: 0;
			/*img{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%))}*/
			img{height: 100%; max-width: none;position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%))}
		}
	}
	.aboutbox{
		h1{margin-bottom: 48px;}
		.rings{left: -90px;}
		.btn-inqury{display: none;}
		.box-three-ikk{
			.list{width: 30.23%; margin-right: 4.14%;}
		}
		.flw-left-service{margin-top: 50%;}
		.flw-left-gallery{margin-top: 70%;}
		.subtitle{margin-bottom: 45px;}
		&.venuegallery{
			padding-bottom: 80px;
			h1{margin-bottom: 105px;}
			.btn-inqury{display: block; right: 60px; top: 156px;}
		}
		&.vendorgallery{
			.flw-left-gallery{margin-top: 120%;}
		}
	}
	.about-story{
		padding-bottom: 50px;
		.flw-right{
			img{width: 300px;}
		}
	}
	.box-service{
	 	.list-service{
	 		.service-desc{
	 			.service-text{width: 300px; max-width: 100%; float: right;}
	 		}
	 		&:nth-child(odd){
	 			.service-desc{
	 				.service-text{float: left;}
	 			}
	 		}
	 	}
	}
	.box-exvenue{
		.list-venue{
			.box-desc{top: 30px; left: 30px; right: 30px; bottom: 30px; padding: 30px 20px;}
		}
	}
	.coming-soon{
		.left{
			.logo{
				img{width: 112px;}
			}
			.content-cs{
				top: 40%;
				.flw-right-cs{top: 100%;}
			}
		}
		.box-partner-cs{left: 30px; width: 44.5%;}
	}
	.title-content{
		h4{
			&.caps-detail{margin-top: 40px; font-size: 25px; line-height: 40px;}
		}
	}
	.box-gallery-detail{
		.box-slide-gallery{
			.slick-prev{left: -36px;}
			.slick-next{right: -36px;}
		}
	}
	.faq-content{
		.btn-inqury{right: 60px; top: 152px;}
		.flw-left-faq{
			margin-top: 110%;
			img{width: 150px;}
		}
		.box-faq{margin-bottom: 80px;}
	}
	.banner-thankyou{
		.desc{
			top: 70%;
			h1{font-size: 70px;}
			.btn-oval{font-size: 14px; padding-left: 20px; padding-right: 20px;}
		}
	}
}
@media(min-width: 1px) and (max-width: 1024px) and (max-height: 750px){
	.coming-soon{
		.left{
			.content-cs{
				.flw-right-cs{top: 85%;}
			}
		}
	}
}

@media(min-width: 1px) and (max-width: 1023px){
	.slidehome{opacity: 1;}
}